import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Cora from "../images/cora.jpg"
import Ada from "../images/ada.jpg"
import Evie from "../images/evie.jpg"
import Holden from "../images/holden2.jpg"
import Margo from "../images/margo.jpg"
import Soon from "../images/fixed-coming-soon.jpg"

const IndexPage = () => (
		<Layout>
			<SEO title="Home" keywords={[`gatsby`, `application`, `react`]}/>

			<div className="ui padded raised segment">
				<div className="container">
					<img src={Soon} style={{maxWidth: "100%", maxHeight: "100%", opactity: 0.7}} alt="Coming Soon"/>
				</div>
			</div>

			<h2 className="ui top attached red header">
				Scrapbook Contents
			</h2>

			<div className="ui padded attached raised segment">
					<div className="ui list">
						<div className="item">
							<i className="big id card icon" />
								<div className="content">
									<h5 className="ui green header">Stats</h5>
									<div className="description">This can include <b>height</b>, <b>weight</b>, <b>hair color</b>, etc.</div>
								</div>
						</div>
						<div className="item">
							<i className="big trophy icon"/>
								<div className="content">
									<h5 className="ui green header">Achievements</h5>
									<div className="description">Maybe Evie won a trophy at <b>Soccer Shots</b>.</div>
								</div>
						</div>
						<div className="item">
							<i className="big camera icon" />
								<div className="content">
									<h5 className="ui green header">Photos</h5>
									<div className="description">Let's be honest, this is <b>obvious</b>, right?</div>
								</div>
						</div>
						<div className="item">
							<i className="big birthday cake icon" />
								<div className="content">
									<h5 className="ui green header">Birthdays</h5>
									<div className="description">Where the icing meets the <b>cake</b>.  Keep track
										of all the kid's birthdays, with <b>ease</b>.</div>
								</div>
						</div>
						<div className="item">
							<i className="big plane icon"/>
								<div className="content">
									<h5 className="ui green header">Vacations</h5>
									<div className="description">Had a blast at the <b>beach</b>? Let's add that to the books.</div>
								</div>
						</div>
					</div>
			</div>

			<h2 className="ui top attached red header">
				Featuring
			</h2>

			<div className="ui padded attached raised segment">
			  <div className="ui relaxed list">
				  <div className="item">
					  <img className="ui tiny circular image" src={Cora} alt="Cora"/>
						  <div className="middle aligned content">
							  <div className="header">Cora</div>
								  <div className="description">
								    First born and heir to the throne. Her alias... <b>"FishFry"</b>
							    </div>
						  </div>
				  </div>
				  <div className="item">
					  <img className="ui tiny circular image" src={Ada} alt="Ada"/>
						  <div className="middle aligned content">
							  <div className="header">Ada</div>
							    <div className="description">
								    Favorite foods include quesadillas and <b>M&M's</b>.  Better known as Ada Beta or Ada Potato,
							    </div>
						  </div>
				  </div>
				  <div className="item">
					  <img className="ui tiny circular image" src={Evie} alt="Evie"/>
						  <div className="middle aligned content">
							  <div className="header">Evie</div>
								  <div className="description">
									  The most feared of the five.  <b>"Danger"</b> is her middle name and being a wild card is the name of the game.
							    </div>
						  </div>
				  </div>
				  <div className="item">
					  <img className="ui tiny circular image" src={Holden} alt="Holden"/>
						  <div className="middle aligned content">
							  <div className="header">Holden</div>
								  <div className="description">
								    Only male.  Lives in constant fear of being surrounded by 4 girls.  AKA: <b>Holdie Bear</b>
							    </div>
						  </div>
				  </div>
				  <div className="item">
					  <img className="ui tiny circular image" src={Margo} alt="Margo"/>
						  <div className="middle aligned content">
							  <div className="header">Margo</div>
								  <div className="description">
								    The easiest of the five.  Can already palm a basketball.  Best known for...<b>Cute Smiles.</b>
							    </div>
						  </div>
				  </div>
			  </div>
			</div>
				<div className="ui two column grid" style={{margin: "2rem auto"}}>
					<div className="column">
						<div className="ui raised segment">
							<p>
								<a href="/" className="ui red ribbon large label">Kid Info</a>All the information you want to keep track of in the traditional baby books
								will be captured in this website.
							</p>
							<p>
								<a href="/" className="ui violet ribbon large label">Images</a>Yeah I know...you could just pull up Google Photos, but this will aggregate
									pictures as well and also add some cool information about the picture or trip. Eventually
									(hopefully), you'll be able to upload your photos from the vacation into the scrapbook (safe & securely)
							</p>
						</div>
					</div>
					<div className="column">
						<div className="ui raised segment">
							<p>
								<a href="/" className="ui orange ribbon label">Vacation Photos</a>Family vacations will be added here
									and will act as more than just a simple photo gallery of the kids and grownups.
							</p>
							<p>
								<a href="/" className="ui teal ribbon label">Birthdays</a>Capture each birthday event
									here.
							</p>
						</div>
					</div>
				</div>
		</Layout>
)

export default IndexPage
